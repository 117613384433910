import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const AuthRoute = ({ children, ...rest }) => {
     // const { user, currentApp } = useSelector(state => state);

     const currentApp = localStorage.getItem('@app')
         ? localStorage.getItem('@app')
         : null;

     const user = localStorage.getItem('@kuposuser')
         ? JSON.parse(localStorage.getItem('@kuposuser'))
         : null;

     const isLoggedIn = user && currentApp;
     return isLoggedIn ? <Route {...rest} /> : <Redirect to="/login" />;
};

export default AuthRoute;
